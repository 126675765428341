<template>
  <div class="fidelizacao-item" @click="$emit('click', $event)">
    <div class="text-renovar">
      Clique para renovar
    </div>
    <div v-if="fidelizacao.cancelado" class="isCancelado">
      <i class="fas fa-ban"></i> Esse cartão foi cancelado
    </div>
    <img
      :src="
        dark
          ? require('@/assets/img/icones/menu/loyalty_dark_40px.png')
          : require('@/assets/img/icones/menu/loyalty_40px.png')
      "
    />

    {{ fidelizacao.titular
    }}<b>{{ fidelizacao.dependente ? " - Dependente" : "" }}</b>

    <div class="fidelizacao-detalhes">
      <p class="fidelizacao-detalhes-item">
        <b>Titular:</b> {{ fidelizacao.titular }}
      </p>
    </div>

    <div class="fidelizacao-codigo"><b>Cód:</b> {{ fidelizacao.codigo }}</div>

    <div class="fidelizacao-validade">
      Val.:
      <small
        >{{ fidelizacao.aquisicao | moment("DD/MM/YYYY") }} até
        {{ fidelizacao.validade | moment("DD/MM/YYYY") }}</small
      >
    </div>

    <div class="fidelizacao-custo-renovacao">
      <b>Custo Renovação:</b>
      {{ fidelizacao.valor_renovacao | currencyWithMask }}
   
    </div>
       <small class="text-muted">
        * Custo de venda normal é {{ fidelizacao.valor | currencyWithMask}}
      </small>
  </div>
</template>

<script>
export default {
  created() {},
  props: {
    fidelizacao: Object,
    dark: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/fidelizacoes.scss";
</style>
